import { render, staticRenderFns } from "./_breadcrumbsBar.vue?vue&type=template&id=5b230f64&scoped=true&"
import script from "./_breadcrumbsBar.vue?vue&type=script&lang=js&"
export * from "./_breadcrumbsBar.vue?vue&type=script&lang=js&"
import style0 from "./_breadcrumbsBar.vue?vue&type=style&index=0&id=5b230f64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b230f64",
  null
  
)

export default component.exports