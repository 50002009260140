<template>
  <div>
    <v-breadcrumbs :items="items" large>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" @click="navigate(item.href)"
        >{{item.text}}</v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>{{mdiChevronRight}}</v-icon>
      </template>
    </v-breadcrumbs>

    <!-- <span class="c-breadcrumbs" v-for="(item, index) in items" :key="index">
      <a class="c-breadcrumbs-item" :href="item.href">{{ item.text}}</a>
      <v-icon v-if="index < (items.length-1)">{{divider}}</v-icon>
    </span> -->
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js';
export default {
  props: {
    pathitems: {
      // type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      mdiChevronRight: mdiChevronRight,
      divider: "mdi-chevron-right",
      host: "",
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "breadcrumbs_dashboard",
          // exact: true,
        },
        {
          text: "Link 1",
          disabled: false,
          href: "/play",
          // exact: true,
        },
        {
          text: "Link 2",
          disabled: false,
          href: "/club/info",
          // exact: true,
        },
      ],
    };
  },
  async created() {
    // let hostname = window.location.hostname;
    // let port =":" + window.location.port;
    // if (window.location.port == "") port = "";
    // this.host = hostname+port+"";
    // console.log(this.pathitems);
    this.items = this.pathitems;
  },
  mounted() {},
  methods: {
    navigate(href) {
      window.location.href = href;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/custom_sass/components/_variables.scss";

.v-breadcrumbs {
  padding-left: 0;
}

.c-breadcrumbs {
  .c-breadcrumbs-item {
    padding: 0.5rem;
  }
}
</style>