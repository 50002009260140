<template>
  <div>
    <v-card class="mx-auto news-card2" shaped @click="navigateToNewsPost">
      <v-img class="news-card2-img" :src="img" :lazy-src="img">
        <div v-if="this.default" class="news-card2-img-overlay"></div>
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="primary lighten-2"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-card-title class="news-card2-title" ref="1">{{ title }}</v-card-title>
      <v-card-subtitle
        v-if="activePost && activePost.getPublishDateReduced()"
      >{{activePost.getPublishDateReduced()}} - {{ activePost.author}}</v-card-subtitle>

      <v-card-text class="news-card2-title" ref="stripped_content" v-html="activePost.content_stripped"></v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="navigateToNewsPost" rounded text>Lees meer ...</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Post from "../data/Post";
export default {
  props: ["post"],
  data() {
    return {
      img: "",
      // defaultImg: "/img/kfc.png",
      defaultImg: "/img/tribuun.jpg",
      title: "Standaard titel",
      content: "",
      default: false,
      activePost: null,
    };
  },
  created() {
    if (this.post != null) {
      this.activePost = Post.fromJson(this.post);
      if (this.activePost.getImage() != null) {
        let img = new Image();

        let THIS = this;
        img.onload = function () {
          THIS.img = THIS.activePost.thumbnail;
        };
        img.src = this.activePost.getImage();
      } else {
        this.img = this.defaultImg;
        this.default = true;
      }
      if (this.activePost.title) this.title = this.activePost.title;
      if (this.activePost.content) this.content = this.activePost.content;

      // console.log(this.activePost.title + ": ", this.activePost.getPublishDate());
    }
  },
  mounted() {
    // this.ellipsizeTextBox();
    // window.addEventListener("resize", this.ellipsizeTextBox);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.ellipsizeTextBox);
  },
  methods: {
    ellipsizeTextBox() {
      let el = this.$refs.stripped_content;
      let text = el.innerHTML;
      // text = this.parseHTMLToText(text);
      let wordArray = text.split(" ");
      el.innerHTML = text;
      while (el.scrollHeight > el.offsetHeight) {
        wordArray.pop();
        el.innerHTML = wordArray.join(" ") + "...";
      }
    },
    parseHTMLToText(text) {
      let out = "";
      //   let str = text.replace(/\<.*?\>/g, " ");
      let str = text.replace(/<[^>]*>?/gm, " ");
      str = str.split(" ");
      for (let i = 0; i < str.length; i++) {
        let word = str[i];
        if (word != "") out = out.concat(word + " ");
      }
      return out;
    },
    navigateToNewsPost() {
      this.$emit("cancelBottomWatcher");
      this.$router.push({
        // path: "nieuws/" + this.post.getID(),
        name: "NieuwsPost",
        params: { id: this.activePost.getID(), post: this.activePost },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/custom_sass/components/_variables.scss";

.news-card2 {
  display: flex;
  flex-direction: column;
  height: 350px;

  .news-card2-img {
    flex: none;
    height: 160px;
  }
  .news-card2-img-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .news-card2:hover {
    cursor: pointer;
  }

  .news-card2-title {
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }

  .DemoGradient {
    background: url("/static/stadion.jpg");
    height: 194px;
    background-size: cover;
    transition: 0.3s;
    transition: background 1s ease-in;

    // background: linear-gradient(
    //     to bottom,
    //     rgba(0, 0, 0, 0) 0%,
    //     rgba(229, 28, 35, 1) 110%
    //   ),
    //   url("https://kfchelson.be/PICS/Welkom/stadion.jpg");
    // background: url("https://kfchelson.be/PICS/Welkom/stadion.jpg");
    //   background-size: cover;
    // height: 194px;
    // transition: background 1s ease-out;

    // background-size: 1px 200px;
    // border-radius: 10px;
    // border: 1px solid #839db0;
    // cursor: pointer;
  }
  .DemoGradient:hover {
    background-position: 194px;
  }
}
</style>