<template>
  <v-app id="inspire" style="overflow:auto;">
    <v-navigation-drawer dark v-model="drawer" fixed style="top:64px;">
      <v-list dense>
        <v-list-item
          v-for="(nav_item, index) in navDrawer"
          :key="index"
          @click="navigate(nav_item.link)"
          :href="nav_item.href"
          router
          link
        >
          <v-list-item-action>
            <v-icon>{{ nav_item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ nav_item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class></v-app-bar-nav-icon>
      <v-toolbar-title>KFC Helson - {{$route.name}}</v-toolbar-title>
    </v-app-bar>

    <div class="container" style="position:absolute;top:64px;left:0;right:0;">
      <router-view style></router-view>
    </div>

    <v-footer app dark style="text-align:center;justify-content: center;">
      <span>&copy; KFC Helson {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import PostService from "../services/PostService";
import { mdiViewDashboard, mdiNewspaper, mdiCog, mdiLogout } from "@mdi/js";
export default {
  props: {
    source: String,
    token: String,
  },
  data: () => ({
    drawer: false,
    navDrawer: [
      // {
      //   icon: mdiViewDashboard,
      //   text: "Dashboard",
      //   link: "/admin/dashboard",
      //   href: "",
      // },
      {
        icon: mdiNewspaper,
        text: "Nieuwsoverzicht",
        link: "/admin/nieuws",
        href: "",
      },
      // {
      //   icon: mdiCog,
      //   text: "Instellingen",
      //   link: "/admin/instellingen",
      //   href: "",
      // },
      {
        icon: mdiLogout,
        text: "Uitloggen",
        link: "/logout",
        href: "/logout",
      },
    ],
  }),
  async created() {
    // if (!window.location.href.endsWith("/admin/dashboard"))
    //   window.location.href = "/admin/dashboard";
    // console.log("ACCESSTOKEN: ", this.token);
    let validAccessToken = await this.checkValidAccessToken();
    // console.log("ADMIN TOKEN CHECK 1");
    if (!validAccessToken) {
      // console.log("ADMIN TOKEN CHECK 2");
      localStorage.setItem("kfch_access_token", this.token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
      // console.log("This token: ", this.token);
      validAccessToken = await this.checkValidAccessToken();
      if (!validAccessToken) {
        // console.log("ADMIN TOKEN CHECK 3");
        // this.$router.push("/login");
      }
    }
  },
  methods: {
    async checkValidAccessToken() {
      // PostService.getPosts().then((result) => {
      //   // let result = await PostService.getPosts();
      //   if (result.response.status >=400)
      //     return false;
      //   return true;
      // });
      // let thisTokenValid = this.token != null && this.token != "" && this.token != "null";
      let localStorageToken = localStorage.getItem("kfch_access_token");
      let localStorageTokenValid =
        localStorageToken != null &&
        localStorageToken != "" &&
        localStorageToken != "null";
      if (localStorageTokenValid) {
        return true;
      }
      return false;
    },
    navigate(target) {
      if (target === "/logout") {
        // console.log("Logging out!");
        localStorage.setItem("kfch_access_token", null);
      }
      if (target != "") {
        this.drawer = false;
        this.$router.push(target);
      }
    },
  },
};
</script>
