<template>
  <div>
    <v-container class>
      <v-row no-gutters>
        <!-- <v-col v-if="psdlink" cols="12" class="action-container">
          <v-btn color="primary" rounded :href="psdlink" :disabled="psdLinkDisabled">
            Pro Soccer Data
            <v-icon>{{mdiChevronRight}}</v-icon>
          </v-btn>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="team-img-container" style>
            <!-- <img class="team-img" src="/img/team-placeholder.png" /> -->

            <v-img :src="loadedImg" :lazy-src="loadedImg" class="team-img">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mdiChevronRight } from "@mdi/js";
export default {
  props: ["img", "psdhref"],
  data() {
    return {
      mdiChevronRight: mdiChevronRight,
      loadedImg: "/img/team-placeholder.png",
      psdlink: "#",
      psdLinkDisabled: false,
    };
  },
  async created() {
    if (this.img != null && this.img != "") this.loadedImg = this.img;

    // this.psdlink = null;
    if (
      this.psdhref != null &&
      this.psdhref != "" &&
      this.psdhref != "null" &&
      this.psdhref != "#"
    ) {
      this.psdlink = this.psdhref;
    } else {
      this.psdLinkDisabled = true;
    }
  },
  mounted() {
    let teamWidgetPlaceholders = document.getElementsByClassName(
      "team-widget-placeholder"
    );
    for (let i = 0; i < teamWidgetPlaceholders.length; i++) {
      // teamWidgetPlaceholders[i].style.height = "auto";
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/custom_sass/components/_variables.scss";
$team_photo_height: 450px;
.team-img-container {
  max-height: $team_photo_height;
  text-align: center;
  display: inline-block;
  width: 100%;
  overflow: auto;

  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  .team-img {
    max-height: $team_photo_height;
    width: 100%;
  }
}
.action-container {
  text-align: right;
  margin-bottom: 1rem;

  @media #{$medium-and-down} {
    text-align: center;
  }
}
</style>