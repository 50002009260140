<template>
  <div>
    <!-- <v-card class="mx-auto" max-width="300" tile> -->
    <v-list shaped>
      <!-- <v-subheader>CLUB</v-subheader> -->
      <v-list-item-group v-model="item" color="primary">
        <v-list-item v-for="(navitem, i) in items" :key="i" :href="navitem.href">
          <!-- <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>-->
          <v-list-item-content>
            <v-list-item-title v-text="navitem.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <!-- </v-card> -->
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: String,
      default: () => {
        return "";
      },
    },
    selection: {
      // type: JSON,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      item: 1,
      items: [
        // { text: "Info", href: "/club/info" },
        // { text: "Dagelijks Bestuur", href: "/club/dagelijks_bestuur" },
        // {
        //   text: "Sportiefverantwoordelijke Senioren",
        //   href: "/club/sportiefverantwoordelijken",
        // },
        // { text: "Jeugdbestuur", href: "/club/jeugdbestuur" },
      ],
    };
  },
  async created() {
    // console.log("SELECTED: ", this.selected);
    // console.log("SELECTION: ", this.selection);
    // this.parseNavProp();
    console.log("aksjdf;kajd;f")
    this.items = this.selection.sub;
    this.chooseSelectedItem(this.selected);

  },
  mounted() {},
  methods: {
    chooseSelectedItem(location) {
      let i = 0;
      //   let location = window.location.href;
      for (let i = 0; i < this.items.length; i++) {
        let navitem = this.items[i];
        if (location.endsWith(navitem.href)) {
          this.item = i;
          return;
        }
      }
    },
    parseNavProp() {
      let o = [];
      for (let i = 0; i < this.selection.length; i++) {
        let nav = this.selection[i];
        let key = Object.keys(nav);
        o.push(nav[key]);
      }
      // console.log(o);
      this.items = o;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/custom_sass/components/_variables.scss";
</style>