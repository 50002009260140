var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dt"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"table-layout":"fixed"},attrs:{"headers":_vm.headers,"items":_vm.newsItems,"sort-by":"created_at","item-key":"id","loading":_vm.loadingNewsItems,"options":_vm.options,"server-items-length":_vm.totalNewsItemsAmt,"footer-props":{
         'items-per-page-text':'Artikelen per pagina'
    }},on:{"update:options":function($event){_vm.options=$event},"toggle-select-all":_vm.paginationHandler,"item-selected":_vm.paginationHandler},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dark":"","text":"","color":"grey-lighten"}},[_c('v-toolbar-title',[_vm._v("Nieuws")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.selectedPosts.length >0)?_c('v-btn',{staticClass:"mb-2",staticStyle:{"margin-right":"1rem"},attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.console.log('verwijder selected posts')}}},[_vm._v("Verwijder posts")]):_vm._e(),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","to":"/admin/nieuwe_post"}},[_vm._v("New Item")])],1)]},proxy:true},{key:"item.title",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticStyle:{"width":"350px"}},[_vm._v(_vm._s(item.title))])]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.getCreateDate()))])]}},{key:"item.published_at",fn:function(ref){
    var item = ref.item;
return [(item.getPublishDate() != null)?_c('span',[_vm._v(_vm._s(item.getPublishDate()))]):_vm._e(),_vm._v(" "),(item.getPublishDate() == null)?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"1rem"}},[_vm._v(_vm._s(_vm.mdiCloseCircle))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","rounded":"","text":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiPencil))]),_vm._v("Bewerken")],1)]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("Er zijn nog geen nieuwsartikelen")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }