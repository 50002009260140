<template>
  <div class="row">
    <div cols="12" class="col-12 col-sm-6 col-md-4" v-for="(person, index) in persons" :key="index">
      <v-card shaped>
        <v-img v-if="person.pic != ''" class="align-end" height="250px" :src="person.pic"></v-img>
        <v-img
          v-if="person.pic == ''"
          class="align-end"
          height="250px"
          src="/img/profile-placeholder.png"
        ></v-img>
        <v-card-subtitle class="enhance-font pb-0">{{ person.title}}</v-card-subtitle>
        <v-card-text class="enhance-font text--primary">
          <p>{{ person.name}}</p>
          <p class="enhance-font">
            <v-icon class="icon-spacing">{{mdiPhone}}</v-icon>
            <a v-if="person.tel" :href="'tel:'+sanitizePhone(person.tel)">{{person.tel}}</a>
            <span v-if="!person.tel">/</span>
          </p>
          <p class="enhance-font person-mail-container">
            <v-icon class="icon-spacing">{{mdiEmail}}</v-icon>
            <a class="person-mail" v-if="person.mail" :href="'mailto:'+person.mail">{{person.mail}}</a>
            <span v-if="!person.mail">/</span>
          </p>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mdiPhone, mdiEmail } from "@mdi/js";
export default {
  props: {
    people: {
      // type: JSON,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      mdiPhone: mdiPhone,
      mdiEmail: mdiEmail,
      persons: [],
    };
  },
  async created() {
    // console.log("PEOPLE: ", this.people);
    this.persons = this.people;
  },
  mounted() {
    // this.adjustTextOverflow();
  },
  methods: {
    sanitizePhone(phone) {
      return phone.replace(/[^0-9]/g, "");
    },
    adjustTextOverflow() {
      let mails = document.getElementsByClassName("person-mail");
      // console.log(mails);
      for (let i = 0; i < mails.length; i++) {
        let mail = mails[i];
        if (mail) {
          if (mail.innerHTML.length > 30) {
            // mail.style.fontSize = "0.8rem";
            // console.log(mail);
            let temp = mail.innerHTML.split("@");
            mail.innerHTML = temp[0] + "-@" + temp[1];
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/custom_sass/components/_variables.scss";
.icon-spacing {
  margin-right: 1.5rem;
}

.person-mail-container {
  display: flex;
  flex-direction: row;
}

.person-mail {
  flex: 1;
  word-break: break-all;
  display: inline-block;
  max-width: 200px;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
}

.enhance-font {
  font-size: 1rem !important;
}
</style>