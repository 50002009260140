<template>
  <div>
    <h1 class="news-container-title">Nieuws</h1>
    <v-row v-if="posts.length == 0" style="justify-content:center;text-align: center;min-height:350px;display: flex;flex-direction: column;">
      <span>Er is voorlopig nog geen nieuws.</span>

    </v-row>
    <v-row v-if="posts.length > 0" ref="post_container" id="post_container">
      <v-col v-for="post in posts" :key="post.getID()" cols="12" class="col-md-4 col-lg-3">
        <ui-nieuws-item
          :post="post"
          :placeholderImg="placeholderImg"
          @cancelBottomWatcher="UnRegisterBottomWatcher"
        ></ui-nieuws-item>
      </v-col>
      <div v-if="true" class="loadingNewPostsWrapper">
        <v-progress-circular v-if="loadingNewPosts" indeterminate color="primary"></v-progress-circular>
      </div>
    </v-row>
  </div>
</template>

<script>
import UINieuwsItem from "../components/UINieuwsItem";
import Post from "../data/Post";
import PostService from "../services/PostService";
export default {
  components: {
    "ui-nieuws-item": UINieuwsItem,
  },
  props: ["blogspagination"],
  data() {
    return {
      page: 1,
      totalPages: 0,
      // isActive: [],
      lazyPosts: [],
      bottom: false,
      bottomWatcher: null,
      paginationParam: 9,

      posts: [],
      placeholderImgURL: "/static/stadion.jpg",
      placeholderImg: null,

      loadingNewPosts: false,
    };
  },
  watch: {},
  mounted() {
    let el = document.getElementById("main");
    // console.log(el);
    //   el.style.backgroundImage = 'url("https://images.unsplash.com/photo-1517747614396-d21a78b850e8")';
    //   el.style.backgroundImage = 'url("https://images.unsplash.com/photo-1574629810360-7efbbe195018")';
    //   el.style.backgroundImage = 'url("https://images.unsplash.com/photo-1459865264687-595d652de67e")';
    //   el.style.backgroundImage = 'url("https://images.unsplash.com/photo-1510051640316-cee39563ddab")';
    //   el.style.backgroundImage = 'url("https://images.unsplash.com/photo-1522778119026-d647f0596c20")';
    // el.style.backgroundImage = 'url("https://images.unsplash.com/photo-1510051640316-cee39563ddab")';
    // el.style.backgroundSize = "cover";
  },
  async created() {
    let THIS = this;
    if (this.blogspagination == null) {
      this.blogspagination = await PostService.getPublishedPosts(
        this.paginationParam,
        this.page,
        true
      );
      this.blogspagination = this.blogspagination.data;
    }
    this.blogspagination.data.forEach(function (post) {
      // console.log(post.title+": "+post.getPublishDate());
      let p = Post.fromJson(post);
      THIS.__pushPost(p);
    });

    this.totalPages = this.blogspagination.last_page;

    this.bottomWatcher = this.$watch("bottom", (bottom) => {
      if (this.page >= this.totalPages) {
        window.removeEventListener("scroll", this.bottomScrollEventHandler);
        this.bottomWatcher();
      }
      if (bottom) {
        this.addPosts();
      }
    });
    // console.log("Adding bottomScrollEventHandler");
    window.addEventListener("scroll", this.bottomScrollEventHandler);
    this.addPosts();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.bottomScrollEventHandler);
  },
  methods: {
    bottomVisible() {
      const scrollY = window.scrollY;
      const viewport = document.documentElement.clientHeight;
      const el = document.documentElement;
      const pageHeight = document.getElementById("post_container").offsetHeight;
      const bottomOfPage = viewport + scrollY >= pageHeight;

      const bottomIsVisible = bottomOfPage || pageHeight < viewport;
      return bottomIsVisible;
    },
    registerBottomWatcher() {
      // console.log("Adding bottomScrollEventHandler");
      window.addEventListener("scroll", this.bottomScrollEventHandler);
    },
    UnRegisterBottomWatcher() {
      // console.log("Removing bottomScrollEventHandler");
      window.removeEventListener("scroll", this.bottomScrollEventHandler);
      this.bottomWatcher();
    },
    bottomScrollEventHandler() {
      this.bottom = this.bottomVisible();
    },
    async addPosts() {
      if (this.page >= this.totalPages) return;
      this.loadingNewPosts = true;
      this.page++;
      let THIS = this;
      // console.log("Fetching page ", this.page);
      PostService.getPublishedPosts(this.paginationParam, this.page).then(
        async (response) => {
          response.data.data.forEach(function (post) {
            THIS.__pushPost(post);
          });
          this.loadingNewPosts = false;
          if (THIS.bottomVisible()) {
            THIS.addPosts();
          }
        }
      );
    },
    __pushPost(newPostJSON) {
      let newPost = Post.fromJson(newPostJSON);
      for (let i = 0; i < this.posts.length; i++) {
        let post = this.posts[i];
        if (post.getID() === newPost.getID()) return;
      }
      this.posts.push(newPost);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/custom_sass/components/_variables.scss";
.news-container-title {
  color: black;
  margin-bottom: 2rem;

}

.news-card {
  //   box-shadow: 0px 3px 11px 0px rgba(0,0,0,0.75);
  height: 500px;
  display: flex;
  flex-direction: column;

  .news-card-container {
    padding: 1.5rem;
  }
  .news-card-title {
    padding: 2rem 1rem;
    font-weight: normal;
    text-overflow: ellipsis;
  }
  .news-card-content {
    font-weight: normal;
    overflow: auto;
  }
}

.loadingNewPostsWrapper {
  display: block;
  width: 100%;
  text-align: center;
  margin: 4rem;
}
</style>