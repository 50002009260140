<template>
  <div>
    <h2>Login - Test</h2>
    <!-- <a href="/auth/sociallogin/redirect/google">bamboozle</a> -->
    <a @click="loginGoogle">bamboozle</a>
    <button @click="AuthProvider('google')">auth Google</button>
    <!--
    <passport-clients></passport-clients>
    <passport-authorized-clients></passport-authorized-clients>
    <passport-personal-access-tokens></passport-personal-access-tokens>-->

    <!-- <GoogleLogin :params="params" :onSuccess="onSuccess" :onFailure="onFailure">Login</GoogleLogin> -->
  </div>
</template>

<script>
// import GoogleLogin from "vue-google-login";
import axios from 'axios';
export default {
  name: "Dashboard",
  components: {
    // GoogleLogin,
  },
  data() {
    return {};
  },
  computed: {
    user: {
      get() {
        return this.$store.state.currentUser.user;
      },
    },
  },
  // Waiting for the callback.blade.php message... (token and username).
  mounted() {
    window.addEventListener("message", this.onMessage, false);
  },

  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  methods: {
    // This method call the function to launch the popup and makes the request to the controller.
    loginGoogle() {
      const newWindow = openWindow("/auth/sociallogin/redirect/google", "message");
      // axios
      //   .get("/auth/sociallogin/redirect/google")
      //   .then((response) => {
      //     newWindow.location.href = response.data;
      //   })
      //   .catch(function (error) {
      //     console.error(error);
      //   });
    },
    // This method save the new token and username
    onMessage(e) {
      if (e.origin !== window.origin || !e.data.token) {
        return;
      }
      localStorage.setItem("user", e.data.name);
      localStorage.setItem("jwt", e.data.token);

      this.$router.go("/board");
    },
    AuthProvider(provider) {
      var bb = this;
      this.$auth
        .authenticate(provider)
        .then((response) => {
          this.SocialLogin(provider, response);
        })
        .catch((err) => {
          // console.log({ err: err });
        });
    },
    SocialLogin(provider, response) {
      // this.$http
      //   .post(
      //     "/api/sociallogin/google",
      //     response
      //   )
      //   .then((response) => {
      //     console.log(response.data);
      //   })
      //   .catch((err) => {
      //     console.log({ err: err });
      //   });
      this.$http
        .get(
          "/auth/redirect/google",
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          },
          response
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => {
          console.log({ err: err });
        });
    },
  },
};

// The popup is launched.

function openWindow(url, title, options = {}) {
  if (typeof url === "object") {
    options = url;
    url = "";
  }

  options = { url, title, width: 600, height: 720, ...options };

  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screen.top;
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    window.screen.height;

  options.left = width / 2 - options.width / 2 + dualScreenLeft;
  options.top = height / 2 - options.height / 2 + dualScreenTop;

  const optionsStr = Object.keys(options)
    .reduce((acc, key) => {
      acc.push(`${key}=${options[key]}`);
      return acc;
    }, [])
    .join(",");

  const newWindow = window.open(url, title, optionsStr);

  if (window.focus) {
    newWindow.focus();
  }

  return newWindow;
}
</script>

<style scoped>
</style>
