import { render, staticRenderFns } from "./UINieuws.vue?vue&type=template&id=abff950e&scoped=true&"
import script from "./UINieuws.vue?vue&type=script&lang=js&"
export * from "./UINieuws.vue?vue&type=script&lang=js&"
import style0 from "./UINieuws.vue?vue&type=style&index=0&id=abff950e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abff950e",
  null
  
)

export default component.exports