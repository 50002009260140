<template>
  <div class="sidenav-container">
    <v-btn class="menu-button" color="rgba(0,0,0,0)" @click.stop="drawer = !drawer">
      <v-icon color="white">{{mdiMenu}}</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-model="drawer"
      right
      fixed
      temporary
      mobile-breakpoint="650"
      height="auto"
      style="bottom:0;"
    >
      <v-list>
        <div v-for="item in items" :key="item.title">
          <v-list-item v-if="item.sub.length == 0" :href="item.href">
            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-group v-for="item in items" :key="item.title" v-model="item.active" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="subItem in item.items" :key="subItem.title" :href="subItem.href">
            <v-list-item-content>
              <v-list-item-title v-text="subItem.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </v-list-group>-->

          <v-list-group v-if="item.sub.length > 0" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title >{{item.title}}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="subItem in item.sub" :key="subItem.title" :href="subItem.href">
              <v-list-item-content>
                <v-list-item-title>{{subItem.short}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mdiMenu } from '@mdi/js';
export default {
  props: ["navs"],
  data() {
    return {
      mdiMenu: mdiMenu,
      drawer: null,
      items: [],
    };
  },
  created() {
    this.parseNavProp();
  },
  mounted() {},
  methods: {
    parseNavProp() {
      let o = [];
      for (let i = 0; i < this.navs.length; i++) {
        let nav = this.navs[i];
        let key = Object.keys(nav);
        o.push(nav[key]);
      }
      // console.log(o);
      this.items = o;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidenav-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 20;
}

.menu-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
}
</style>
