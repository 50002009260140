<template>
  <div id="dt">
    <v-data-table
      style="table-layout:fixed"
      :headers="headers"
      :items="newsItems"
      sort-by="created_at"
      class="elevation-1"
      item-key="id"
      :loading="loadingNewsItems"
      :options.sync="options"
      :server-items-length="totalNewsItemsAmt"
      :footer-props="{
           'items-per-page-text':'Artikelen per pagina'
      }"
      @toggle-select-all="paginationHandler"
      @item-selected="paginationHandler"
    >
      <template v-slot:top>
        <v-toolbar dark text color="grey-lighten">
          <v-toolbar-title>Nieuws</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            v-if="selectedPosts.length >0"
            color="primary"
            dark
            class="mb-2"
            @click="console.log('verwijder selected posts')"
            style="margin-right:1rem;"
          >Verwijder posts</v-btn>
          <v-btn color="primary" dark class="mb-2" to="/admin/nieuwe_post">New Item</v-btn>
        </v-toolbar>
      </template>

      <!-- <template slot="items" slot-scope="props">
        <tr>
          <td>{{ props.item.title }}</td>
          <td>{{ props.item.getPublishDate() }}</td>
          <td>{{ props.item.getCreatedDate() }}</td>
          <td><v-icon small class="mr-2" @click="editItem(props.item)">{{mdiPencil}}</v-icon>Bewerken</td>
        </tr>
      </template> -->

      <template v-slot:item.title="{ item }" style="width:350px;">
        <td style="width:350px;">{{ item.title }}</td>
      </template>
      <template v-slot:item.created_at="{ item }">
        <span>{{ item.getCreateDate() }}</span>
      </template>
      <template v-slot:item.published_at="{ item }">
        <span v-if="item.getPublishDate() != null">{{ item.getPublishDate() }}</span>
        <v-icon v-if="item.getPublishDate() == null" class="mr-2" size="1rem">{{mdiCloseCircle}}</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="primary" @click="editItem(item)" rounded text><v-icon small class="mr-2" >{{mdiPencil}}</v-icon>Bewerken</v-btn>
      </template>
      <template v-slot:no-data>
        <span>Er zijn nog geen nieuwsartikelen</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Post from "../data/Post";
import PostService from "../services/PostService";
import { mdiCloseCircle, mdiPencil, mdiDelete } from "@mdi/js";

export default {
  data: () => ({
    mdiCloseCircle: mdiCloseCircle,
    mdiPencil: mdiPencil,
    mdiDelete: mdiDelete,
    dialog: false,
    headers: [
      // { text: "", value: "thumbnail", sortable: true },
      {
        text: "Titel",
        value: "title",
        sortable: false,
        width: "300px",
        fixed: true,
      },
      {
        text: "Gepubliceerd",
        align: "center",
        value: "published_at",
        sortable: true,
      },
      { text: "Aangemaakt op", value: "created_at", sortable: true },
      { text: "Acties", value: "actions", sortable: false },
    ],
    newsItems: [],
    activeNewsItems: [],
    editedIndex: -1,
    editedItem: new Post(),
    defaultItem: new Post(),
    loadingNewsItems: false,
    totalNewsItemsAmt: 0,
    options: {
      itemsPerPage: 10,
      sortDesc: [true, true],
    },
    currentPage: 1,
    error: {
      title: null,
      content: null,
    },
    lastUpdate: new Date(),
    sortDirection: [true, true],

    selectedPosts: [],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    options: {
      async handler(val) {
        // console.log("Handle update on options: "), val;
        if (!this.loadingNewsItems) {
          let data = await this.getDataFromApi();
          // console.log(data);
          this.newsItems = data.items;
          this.totalNewsItemsAmt = data.total;
        }
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
  },
  async mounted() {
    if (!this.loadingNewsItems) {
      let data = await this.getDataFromApi();
      this.newsItems = data.items;
      this.totalNewsItemsAmt = data.total;
    }
  },
  async created() {},
  methods: {
    openDialog() {
      this.dialog = true;
    },
    editItem(item) {
      this.$router.push({
        name: "Bewerk post",
        // path: "bewerk_post/" + item.id,
        params: { id: item.id, post: item, mode: "EDIT" },
      });
    },
    deleteItem(item) {
      const index = this.newsItems.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.newsItems.splice(index, 1);
      PostService.deletePost();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    async paginationHandler(pagination) {
      // console.log("selected items: ", pagination);
      if (pagination.items && pagination.items.length > 0)
        this.selectedPosts = pagination.items;
      else this.selectedPosts = [];
    },
    selectedItemHandler(selectedItem) {
      // console.log("selected items: ", selectedItem);
      if (selectedItem.item) this.selectedPosts = selectedItem.items;
      else this.selectedPosts = [];
    },
    async getDataFromApi() {
      this.loadingNewsItems = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      // console.log(this.options.sortDesc);

      return await PostService.getPosts(itemsPerPage, page).then((response) => {
        let items = response.data.data;
        const total = response.data.total;

        if (sortBy.length === 1 && sortDesc.length === 1) {
          items = items.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              return 0;
            } else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              return 0;
            }
          });
        }
        this.loadingNewsItems = false;
        return { items, total };
      });
    },
  },
};
</script>