<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="navigation-container">
        <v-btn @click="navigateBack" text small rounded>
          <v-icon style="margin-right:1rem;">{{mdiArrowLeft}}</v-icon>Nieuwsoverzicht
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-form ref="form" v-model="valid" :lazy-validation="lazy" class="form-container">
        <div class="form-top">
          <div align="right">
            <v-row class="align-items-center">
              <v-col col="12" sm="3">
                <v-switch v-model="activePost.published" label="Gepubliceerd"></v-switch>
              </v-col>
              <v-col col="12" sm="9">
                <v-btn rounded color="error" dark @click="callDeletePostDialog" v-if="true">
                  <v-icon left>{{mdiTrashCanOutline}}</v-icon>Verwijder
                </v-btn>
                <v-btn :disabled="!enableSave" v-if="inMode('CREATE')" rounded color="success" :dark="enableSave" @click="createPost">
                  <v-icon left>{{mdiContentSaveOutline}}</v-icon>Sla op
                </v-btn>
                <v-btn :disabled="!enableSave"
                  v-if="inMode('CREATE')"
                  rounded
                  color="success"
                  :dark="enableSave"
                  @click="createPostAndPreview"
                >
                  <v-icon left>{{mdiFileEyeOutline}}</v-icon>Sla op en preview
                </v-btn>
                <v-btn :disabled="!enableSave" v-if="inMode('EDIT')" rounded color="success" :dark="enableSave" @click="editPost">
                  <v-icon left>{{mdiPencil}}</v-icon>Sla wijzigingen op
                </v-btn>
                <v-btn :disabled="!enableSave" 
                  v-if="inMode('EDIT')"
                  rounded
                  color="success"
                  :dark="enableSave"
                  @click="editPostAndPreview"
                >
                  <v-icon left>{{mdiFileEyeOutline}}</v-icon>Sla wijzigingen op en preview
                </v-btn>
                <v-btn v-if="inMode('EDIT')" rounded color="success" dark @click="preview(activePost.getID())">
                  <v-icon left>{{mdiFileEyeOutline}}</v-icon>Preview
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <v-row>
            <v-col xs="12">
              <v-text-field
                v-model="activePost.title"
                :counter="140"
                :rules="titleRules"
                label="Titel"
                required
                @update:error="enableSaveWatcher"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col xs="12">
              <v-btn class="cover-input" color="primary" dark rounded @click="chooseImage">
                <v-icon style="margin-right:1rem;">{{mdiImage}}</v-icon>Afbeelding uploaden
                <input
                  class="file-input"
                  id="fileInputSetImage"
                  ref="fileInput0"
                  type="file"
                  @input="onSelectFile"
                  accept="image/png, image/jpeg"
                />
              </v-btn>

              <v-btn
                v-if="activePost.getImage()"
                class="cover-input"
                color="primary"
                dark
                rounded
                @click="removeImage"
              >
                <v-icon style="margin-right:1rem;">{{mdiImageRemove}}</v-icon> Verwijder afbeelding
                <input
                  class="file-input"
                  ref="fileInput1"
                  type="file"
                  @input="onSelectFile"
                  multiple
                />
              </v-btn>
            </v-col>
            <v-col xs="12" class="metadata align-items-center">
              <div class="metadata-sub">
                <span style="margin-right: 0.8rem;">door {{ activePost.author}}</span> |
                <span>
                  <v-icon style="margin: 0 0.8rem;">{{mdiClockOutline}}</v-icon>
                  {{activePost.getCreateDate()}}
                </span>
              </div>
            </v-col>
          </v-row>
        </div>

        <div class="form-mid">
          <vue-editor v-model="activePost.content" :editorToolbar="customToolbar" style></vue-editor>
          <div class="pull-tab"></div>
        </div>

        <!-- <div class="ql-snow">
          <div class="ql-editor" v-html="activePost.content"></div>
        </div>-->
        <div v-if="uploadedImage" class="preview-container form-bottom">
          <div class="preview">
            <div style="max-height=500px;" id="preview-img"></div>
            <!-- <v-img contain :src="activePost.getImage()" max-height="600px" style="display=block;"></v-img> -->
          </div>
        </div>
        <div v-if="activePost.getImage() != null" class="preview-container form-bottom">
          <div class="preview">
            <v-img contain :src="activePost.getImage()" max-height="600px" style="display=block;"></v-img>
          </div>
        </div>
      </v-form>
    </v-row>
    <v-row>
      <v-dialog v-model="deletePostDialog" persistent max-width="400">
        <v-card style="text-align:center;justify-content: center;">
          <v-card-title class="headline">Verwijder post</v-card-title>
          <v-card-text>Weet je zeker dat je deze post wil verwijderen?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color rounded text @click="deletePostDialog = false">Annuleren</v-btn>
            <v-btn color="primary" rounded @click="deletePost">Verwijder</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="loading" persistent max-width="400">
        <v-card style="text-align:center;justify-content: center;">
          <v-card-title class="headline">{{loadingTask}}</v-card-title>
          <v-card-text>
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="taskSuccess" persistent max-width="400">
        <v-card style>
          <v-card-title class="headline">{{taskSuccessMsgTitle}}</v-card-title>
          <v-card-text>{{taskSuccessMsg}}</v-card-text>
          <v-card-actions style="justify-content:center;">
            <v-btn color="primary" @click="navigateToNewsOverview" rounded text>
              <v-icon style="margin-right:1rem;">{{mdiArrowLeft}}</v-icon>Naar het nieuwsoverzicht
            </v-btn>
            <!-- <v-btn color="primary" @click="taskSuccess = false" rounded text>
              <v-icon style="margin-right:1rem;">{{mdiPencil}}</v-icon>Bewerk
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="error" persistent max-width="400">
        <v-card style>
          <v-card-title class="headline">Oeps!</v-card-title>
          <v-card-text>
            Er is iets misgegaan. Probeer het eens opnieuw.
            <br />Als het probleem blijft aanhouden, contacteer dan de ontwikkelaar.
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="navigateToNewsOverview" rounded text>
              <v-icon style="margin-right:1rem;">{{mdiArrowLeft}}</v-icon>Keer terug naar het overzicht
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import BaseImageInput from "../components/BaseImageInput";
import Post from "../data/Post";
import PostService from "../services/PostService";
import {
  mdiArrowLeft,
  mdiTrashCanOutline,
  mdiContentSaveOutline,
  mdiPencil,
  mdiImage,
  mdiImageRemove,
  mdiClockOutline,
  mdiFileEyeOutline,
} from "@mdi/js";

export default {
  components: {
    VueEditor,
  },
  props: {
    post: Post,
    mode: String,
  },
  data: () => ({
    // Icons
    mdiArrowLeft: mdiArrowLeft,
    mdiTrashCanOutline: mdiTrashCanOutline,
    mdiContentSaveOutline: mdiContentSaveOutline,
    mdiPencil: mdiPencil,
    mdiImage: mdiImage,
    mdiImageRemove: mdiImageRemove,
    mdiClockOutline: mdiClockOutline,
    mdiFileEyeOutline: mdiFileEyeOutline,

    // Form rules
    valid: true,
    titleRules: [
      (v) => !!v || "Geef een titel op",
      (v) =>
        (v && v.length <= 140) || "De titel mag max. 140 karakters bevatten",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "Geef een e-mail adres op",
      (v) => /.+@.+\..+/.test(v) || "Het e-mail adres is niet geldig",
    ],
    select: null,
    // items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    // checkbox: false,
    lazy: false,

    // Editor
    customToolbar: [
      [
        {
          font: [],
        },
      ],
      [
        {
          header: [false, 1, 2, 3, 4, 5, 6],
        },
      ],
      [
        {
          size: ["small", false, "large", "huge"],
        },
      ],
      ["bold", "italic", "underline", "strike"],
      [
        {
          align: "",
        },
        {
          align: "center",
        },
        {
          align: "right",
        },
        {
          align: "justify",
        },
      ],
      ["blockquote"],
      [
        {
          list: "ordered",
        },
        {
          list: "bullet",
        },
      ],
      [
        {
          script: "sub",
        },
        {
          script: "super",
        },
      ],
      [
        {
          indent: "-1",
        },
        {
          indent: "+1",
        },
      ],
      [
        {
          color: [],
        },
        {
          background: [],
        },
      ],
      ["link"],
      ["clean"],
    ],

    enableSave: true,

    // Image upload
    imageFile: null,
    imageData: null,
    uploadedImage: false,
    activeImageBackup: null,

    // Loading dialog
    loading: false,
    loadingTask: "",

    // taskSuccess dialog
    taskSuccess: false,
    taskSuccessMsgTitle: "",
    taskSuccessMsg: "",

    // Error dialog
    error: false,

    // Delete post dialog
    deletePostDialog: false,

    // Active post
    activePost: new Post(
      "Titel",
      "Schrijf hier je post",
      "",
      "",
      "",
      new Date()
    ),
  }),
  async mounted() {},
  async created() {
    let id = this.$route.params.id;

    if (this.post != null) {
      this.activePost = this.post;
      this.activeImageBackup = this.activePost.getImage();
    } else {
      if (id) {
        PostService.getPost(id)
          .then((post) => {
            this.activePost = post;
            this.activeImageBackup = this.activePost.getImage();
          })
          .catch((error) => {
            console.error("Error: ", error);
          });
      }
    }
  },
  beforeDestroy() {
    window.document.removeEventListener("focus", this.onSelectFile);
    window.document.removeEventListener("mousemove", this.onSelectFile);
  },
  methods: {
    enableSaveWatcher(disableSave) {
      this.enableSave = !disableSave;
    },
    chooseImage(val) {
      this.$refs.fileInput0.click();
      window.document.addEventListener("focus", this.onSelectFile);
      window.document.addEventListener("mousemove", this.onSelectFile);
    },
    removeImage(val) {
      this.activePost.thumbnail = null;
    },
    inMode(mode) {
      let currentMode = null;
      let id = this.activePost.getID();
      if (id == null) currentMode = "CREATE";
      else currentMode = "EDIT";

      if (mode === currentMode) return true;
      return false;
    },
    preview(id){
      window.open("/preview/" + id, "_blank");
    },
    createPostAndPreview() {
      this.createPost().then((response) => {
        let post = Post.fromJson(response);
        this.preview(post.getID());
      });
    },
    async createPost() {
      let formData = this.__prepareDataForUpload("C");

      this.__setLoadingTask("C");
      return await PostService.createPost(formData)
        .then((response) => {
          this.loading = false;
          this.__settaskSuccessMsg("C");
          return response;
          // this.$router.push({ name: "Nieuwsoverzicht" });
        })
        .catch((error) => {
          this.error = true;
        });
    },
    editPostAndPreview() {
      this.editPost().then((response) => {
        let post = Post.fromJson(response);
        this.preview(post.getID());
      });
    },
    async editPost() {
      let formData = this.__prepareDataForUpload("U");

      this.__setLoadingTask("U");
      return await PostService.updatePost(formData)
        .then((response) => {
          this.loading = false;
          this.__settaskSuccessMsg("U");
          return response;
          // this.$router.push({ name: "Nieuwsoverzicht" });
        })
        .catch((error) => {
          this.error = true;
        });
    },
    async deletePost() {
      const formData = new FormData();
      formData.append("id", this.activePost.id);
      formData.append("_method", "delete");

      this.__setLoadingTask("D");
      await PostService.deletePost(formData)
        .then(() => {
          this.loading = false;
          this.__settaskSuccessMsg("D");
        })
        .catch((error) => {
          this.error = true;
        });

      this.$router.push({ name: "Nieuwsoverzicht" });
    },
    callDeletePostDialog() {
      this.deletePostDialog = true;
    },
    navigateBack() {
      this.$router.go(-1);
    },
    navigateToNewsOverview() {
      this.$router.push({ name: "Nieuwsoverzicht" });
    },
    onSelectFile(fileInput) {
      window.document.removeEventListener("focus", this.onSelectFile);
      window.document.removeEventListener("mousemove", this.onSelectFile);

      const input = this.$refs.fileInput0;
      if (input && input.files.length > 0) {
        this.uploadedImage = true;
        const files = input.files[0];
        this.imageFile = files;
        const reader = new FileReader();

        this.activePost.thumbnail = null;
        reader.onload = (e) => {
          this.imageData = e.target.result;
          this.__createImgNode(this.imageData, "preview-img");
        };
        reader.readAsDataURL(files);
        this.$emit("input", files);
      } else {
        this.activePost.thumbnail = this.activeImageBackup;
        console.log(this.activePost.thumbnail);
      }
    },
    __prepareDataForUpload(mode) {
      let formData = new FormData();
      if (this.imageFile)
        formData.append("image", this.imageFile, this.imageFile.name);
      else if (this.activePost.getImage())
        formData.append("image", this.activePost.getImage());
      formData.append("title", this.activePost.title);
      formData.append("content", this.activePost.content);
      formData.append("author", this.activePost.author);

      if (this.activePost.isPublished())
        formData.append("published", this.activePost.publish());

      switch (mode) {
        case "C":
          mode = "post";
          formData.append("_method", mode);
          break;
        case "U":
          mode = "put";
          formData.append("id", this.activePost.id);
          formData.append("_method", mode);
          break;
        default:
          throw Error("Invalid upload mode selected!");
      }
      return formData;
    },
    __setLoadingTask(mode) {
      this.loading = true;
      switch (mode) {
        case "C":
          this.loadingTask = "Post wordt opgeslagen...";
          break;
        case "U":
          this.loadingTask = "Wijzigingen worden opgeslagen...";
          break;
        case "D":
          this.deletePostDialog = false;
          this.loadingTask = "Post wordt verwijderd...";
          break;
        default:
          throw Error("Invalid upload mode selected!");
      }
    },
    __settaskSuccessMsg(mode) {
      this.taskSuccess = true;
      switch (mode) {
        case "C":
          this.taskSuccessMsgTitle = "Opgeslagen";
          this.taskSuccessMsg = "Je post is successvol opgeslagen.";
          break;
        case "U":
          this.taskSuccessMsgTitle = "Opgeslagen";
          this.taskSuccessMsg = "De wijzigingen werden successvol opgeslagen.";
          break;
        case "D":
          this.taskSuccessMsgTitle = "Verwijderd";
          this.taskSuccessMsg = "Je post is successvol verwijderd.";
          break;
        default:
          throw Error("Invalid upload mode selected!");
      }
    },
    __createImgNode(imgData, id) {
      let parent = document.getElementById(id);
      if (parent.childNodes.length > 0) parent.childNodes[0].remove();
      let img = document.createElement("img");

      img.style.display = "block";
      img.style.width = "100%";
      img.style.backgroundSize = "contain";
      img.style.backgroundPosition = "center";
      img.style.margin = "0 auto";
      img.src = imgData;
      document.getElementById(id).appendChild(img);
    },
  },
};
</script>

<style lang="scss">
@import "@/custom_sass/components/_variables.scss";

.navigation-container {
  padding-left: 0rem !important;
  // margin-left: 3rem;

  @media #{$medium-and-down} {
    margin-left: 0rem;

    .v-btn {
      padding-left: 0 !important;
    }
  }
}

.form-container {
  flex-flow: column;
  align-items: stretch;
  margin: 0 auto;
  margin-bottom: 4rem;
  width: 100%;

  .form-top {
    flex: 0 1 auto;
  }

  .form-mid {
    flex: 1 1 auto;
    overflow: visible;
  }

  .form-bottom {
    flex: 0 1 60px;
  }
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  overflow: visible !important;
  padding-bottom: 3rem !important;
  resize: vertical;

  .ql-editor {
    height: 100%;
  }
}

.ql-toolbar.ql-snow + .ql-container.ql-snow::resizer {
  border: 2px solid black;
  background: red;
  box-shadow: 0 0 5px 5px blue;
  outline: 2px solid yellow;
}

.pull-tab {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 1rem 1rem;
  border-color: transparent transparent #e51c23 transparent;
  float: right;
  position: relative;
  right: 0;
  top: -1rem;
}

.base-image-input {
  display: block;
  background-size: 100%;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  background-size: contain;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.preview-container {
  max-height: 600px;
  text-align: center;
  width: 100%;
  .preview {
    display: inline-block;
    width: 100%;
  }
}

.metadata {
  display: flex;
  justify-content: center;

  .metadata-sub {
    width: 100%;
    text-align: right;
  }
}
</style>
