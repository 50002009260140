import moment from 'moment';

export default class Post
{
    static get DUTCH_MONTHS() { return ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December']; }

    constructor(title = "", content = "", author = "", thumbnail = "", published_at = "", created_at = "", id = "", content_stripped = "")
    {
        if (title != "") this.title = title;
        if (content != "") this.content = content;
        if (content_stripped != "") this.content_stripped = content_stripped;

        if (thumbnail != "" && thumbnail != "null" && thumbnail != null)
            this.thumbnail = '/uploads/' + thumbnail;
        else 
        {
            this.thumbnail = null;
            // this.thumbnail = "https://via.placeholder.com/150";
        }
        if (published_at != "" && published_at != null)
        {
            // this.published_at = published_at;

            this.published_at = new Date(published_at);
        }
        else
            this.published_at = null;

        if (created_at != "")
        {
            let created_at_unparsed = new Date(created_at);
            this.created_at = created_at_unparsed;
            // this.created_at = created_at;
        }
        else
        {
            this.created_at = new Date();
        }
        if (published_at == "" | published_at == null)
        {
            this.published = false;
        } else 
        {
            this.published = true;
        }
        if (author != "") this.author = author;
        if (id != "") this.id = id;
    }

    getID()
    {
        if (this.id === "" || this.id === null || this.id === "null")
            return null;
        else
            return this.id;
    }

    getImage()
    {
        return this.thumbnail;
    }

    getCreateDate()
    {

        return this.created_at.getDate() + " " + Post.DUTCH_MONTHS[this.created_at.getUTCMonth()] + " " + this.created_at.getFullYear() + " - " + ('0' + this.created_at.getHours()).slice(-2) + ":" + ("0" + this.created_at.getMinutes()).slice(-2);
    }

    isPublished() { return this.published; }

    publish()
    {
        this.published = true;
        this.published_at = new Date();
        return Post.exportDate(this.published_at);
    }

    getPublishDate()
    {
        // if (this.published_at != null && this.published_at != "")
        if (this.published)
            return this.published_at.getDate() + " " + Post.DUTCH_MONTHS[this.published_at.getUTCMonth()] + " " + this.published_at.getFullYear() + " - " + ('0' + this.published_at.getHours()).slice(-2) + ":" + ("0" + this.published_at.getMinutes()).slice(-2);
        return null;
    }

    getPublishDateReduced()
    {
        // if (this.published_at != null && this.published_at != "")
        if (this.published)
            return this.published_at.getDate() + "/" + (this.published_at.getUTCMonth()+1) + "/" + this.published_at.getFullYear() ;
        return null;
    }

    static exportDate(date)
    {
        if (date instanceof Date)
        {
            // Export in format YYYY-MM-DDTHH:mm:ss.sssZ
            return date.getFullYear() + "-" +                  // Year: YYYY
                ('00' + (date.getUTCMonth()+1)).slice(-2) + "-" +   // Month: MM
                ('00' + date.getDate()).slice(-2) +             // Day: DD
                " " +
                ('00' + date.getHours()).slice(-2) + ":" +       // Hours: HH
                ("00" + date.getMinutes()).slice(-2) + ":" +    // Minutes: mm
                ("00" + date.getSeconds()).slice(-2)           // Seconds: ss
                // + "." +("000000") +                                    // Milisecs: sss
                // "Z";
                ;

        } else
        {
            return null;
        }
    }

    static fromJson(post)
    {
        if (post instanceof Post) return post;
        if (post.published == null || post.published == "null" || post.published == "")
            post.published = null;
        else
        {
            // post.published = new Date(post.published);
            post.published = moment(post.published, "YYYY-MM-DD HH:mm:ss").toDate();
            // let m = moment("2020-09-21 11:56:01", "YYYY-MM-DD HH:mm:ss").toDate();
            // console.log(m);
            // console.log(Date.parseExact("2020-09-21 11:56:01", "YYYY-MM-DD HH:mm:ss"))
        }

        if (post.created_at == null || post.created_at == "null" || post.created_at == "")
            post.created_at = null;
        else
        {
            post.created_at = new Date(post.created_at);
            post.created_at = moment(post.created_at, "YYYY-MM-DD HH:mm:ss").toDate();
        }

        if (post.title == "a")
            console.log("Debug time")


        return new Post(
            post.title,
            post.content,
            post.author,
            post.image,
            post.published,
            post.created_at,
            post.id,
            post.content_stripped
        );
    }
}