<template>
  <div>
    <div
      class="base-image-input"
      :style="{ 'background-image': `url(${imageData})` }"
      @click="chooseImage"
    >
      <span v-if="!imageData" class="placeholder">Choose an Image</span>
      <input class="file-input" ref="fileInput" type="file" @input="onSelectFile" multiple />
    </div>
    <v-carousel style="max-width:500px">
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  props: {
    source: String,
  },
  data: () => ({
    imageData: null,
    items: [],
  }),
  created() {},
  methods: {
    chooseImage() {
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      for (let i = 0; i < files.length; ++i) {
        if (files[i]) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageData = e.target.result;
            if (this.imageData != null) {
              this.items.push({ src: this.imageData });
            }
          };
          reader.readAsDataURL(files[i]);
          this.$emit("input", files[i]);
        }
      }
    },
  },
};
</script>

<style lang="scss"  scoped>
.base-image-input {
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
}
.file-input {
  display: none;
}
</style>