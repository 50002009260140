/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';

import UINieuwsContainer from './components/UINieuwsItemContainer';
import UINieuwsItemPost from './components/UINieuwsItemPost';
import NewPost from './vue-pages/NewPost';
import News from './vue-pages/News';
import vuetify from './vuetify';

// const NewPost = () => import(/* webpackChunkName: "news" */ './vue-pages/NewPost')
// const News = () => import(/* webpackChunkName: "news" */ './vue-pages/News')
// const UINieuwsContainer = () => import(/* webpackChunkName: "ui-news" */ './components/UINieuwsItemContainer')
// const UINieuwsItemPost = () => import(/* webpackChunkName: "ui-news" */ './components/UINieuwsItemPost')


// import ClubInfo from './vue-pages/ClubInfo';
// const ClubInfo = () => import('./vue-pages/ClubInfo')
// import Dashboard from './vue-pages/Dashboard';
// import Roles from './vue-pages/Roles';
// import Users from './vue-pages/Users';
// import Settings from './vue-pages/Settings';
// import VueSocialauth from 'vue-social-auth';



// import Login from './vue-pages/Login';
Vue.use(VueAxios, axios)
Vue.use(VueRouter)
// Vue.use(VueSocialauth, {

//     providers: {
//         google: {
//             clientId: '742088182904-rf53nk845p60e9strnl2u2qn5lavrqdp.apps.googleusercontent.com',
//             redirectUri: 'http://me.kfchelson.com:8000/callback/google' // Your client app URL
//         }
//     }
// })
window.Vue = require('vue');

let vm1 = new Vue();
window.eventBus = vm1;

window.eventBus.$on('dataLoaded', function (data)
{
    // react on the event somehow 
    // console.log('dataLoaded');
    // console.log('LOAD FOOTER');
    document.getElementById('footbar').style.display = "block";
});

Vue.use(VueRouter);
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example', require('./components/ExampleComponent.vue').default);

const LocalNav = require(/* webpackChunkName: "local-nav" */ './components/_localNav.vue').default;
const BreadcrumbsBar = require(/* webpackChunkName: "local-nav" */ './components/_breadcrumbsBar.vue').default;
const ProfileThumbnail = require(/* webpackChunkName: "local-nav" */ './components/_profileThumbnail.vue').default;
const TeamWidget = require('./components/_teamWidget.vue').default;
const BaseImageInput = require(/* webpackChunkName: "admin" */ './components/BaseImageInput.vue').default;
const Admin = require(/* webpackChunkName: "admin" */ './components/Admin.vue').default;
const Login = require(/* webpackChunkName: "admin" */ './vue-pages/Login.vue').default;

Vue.component('slideshow', require('./components/Slideshow.vue').default);
Vue.component('base-image-input', BaseImageInput);
Vue.component('sidenav', require('./components/Sidenav.vue').default);
Vue.component('homeslider', require('./components/HomeSlider.vue').default);
Vue.component('breadcrumbs-bar', BreadcrumbsBar);
// Vue.component('local-nav', require('./components/_localNav.vue').default);
Vue.component('local-nav', LocalNav);
Vue.component('profile-thumbnail', ProfileThumbnail);
Vue.component('team-widget', TeamWidget);
// Vue.component('team-widget', TeamWidget);
// Vue.component('login-snackbar', require('./components/_loginSnackbar.vue').default);



Vue.component('Admin', Admin);
Vue.component('Login', Login);
Vue.component('clubinfo', require('./vue-pages/ClubInfo.vue').default);
Vue.component('ui-nieuws', require('./vue-pages/UINieuws.vue').default);
Vue.component('ui-nieuws-item', require('./components/UINieuwsItem.vue').default);
Vue.component('ui-nieuws-item-clone', require('./components/UINieuwsItemClone.vue').default);
Vue.component('ui-nieuws-item-post', require('./components/UINieuwsItemPost.vue').default);
Vue.component('ui-nieuws-item-container', require('./components/UINieuwsItemContainer.vue').default);
Vue.component('ui-nieuws-item-container', require('./components/UINieuwsItemContainer.vue').default);

// Lazy load components


// const LocalNav = () => import(/* webpackChunkName: "local-nav" */ './components/_localNav.vue');
// const BreadcrumbsBar = () => import(/* webpackChunkName: "local-nav" */ './components/_breadcrumbsBar.vue');
// const ProfileThumbnail = () => import(/* webpackChunkName: "local-nav" */ './components/_profileThumbnail.vue');
// const TeamWidget = () => import(/* webpackChunkName: "local-nav" */ './components/_teamWidget.vue');
// const BaseImageInput = () => import(/* webpackChunkName: "admin" */ './components/BaseImageInput.vue');
// const Admin = () => import(/* webpackChunkName: "admin" */ './components/Admin.vue');
// const Login = () => import(/* webpackChunkName: "admin" */ './vue-pages/Login.vue');


const routes = [
    // {
    //     name: "Dashboard",
    //     path: '/admin/dashboard',
    //     component: Dashboard
    // },
    // {
    //     name: "Gebruikers",
    //     path: '/admin/users',
    //     component: Users
    // },
    // {
    //     name: "",
    //     path: '/admin/roles',
    //     component: Roles
    // },
    {
        name: "Nieuwsoverzicht",
        path: '/admin/nieuws',
        component: News
    },
    {
        name: "Niewsoverzicht",
        path: '/admin',
        component: News
    },
    {
        name: "Nieuwe post",
        path: '/admin/nieuwe_post',
        component: NewPost,
    },
    {
        name: "Bewerk post",
        path: '/admin/bewerk_post/:id',
        component: NewPost,
        props: true
    },
    // {
    //     name: "Instellingen",
    //     path: '/admin/instellingen',
    //     component: Settings
    // },
    {
        name: "Nieuws",
        path: '/nieuws',
        component: UINieuwsContainer,
        props: true
    },
    {
        name: "NieuwsPost",
        path: '/nieuws/:id',
        component: UINieuwsItemPost,
        props: true
    },
    // {
    //     name: "clubinfo",
    //     path: '/clubinfo',
    //     component: ClubInfo,
    // },
    // {
    //     path: '/login2',
    //     component: Login
    // },
    {
        path: '/auth/:provide/callback',
        component: {
            template: '<div class="auth-component"></div>'
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    routes
})

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const app = new Vue({
    vuetify,
    router,
    el: '#app',
});
