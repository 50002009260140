<template>
  <div v-if="activePost" class="container">
    <v-row class="news-content-container">
      <v-col class="col-12">
        <h1 class="news-title">{{ activePost.title}}</h1>
        <div class="author-container">
          <v-avatar color="primary" size="48">
            <span class="white--text headline">{{getAuthor()}}</span>
          </v-avatar>
          <div class="author-text-container">
            <span>{{activePost.author}}</span>
            <br />
            <span>{{activePost.getPublishDate()}}</span>
          </div>
        </div>
        <div v-if="activePost.getImage()" class="news-img-container">
          <v-img class="news-img" contain :src="activePost.getImage()"></v-img>
        </div>
        <div class="ql-snow">
          <div class="ql-editor ql-adapter" v-html="activePost.content"></div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import Post from "../data/Post";
import PostService from "../services/PostService";
export default {
  inject: ["theme"],
  props: ["post", "blogspagination"],
  data() {
    return {
      activePost: null,
    };
  },
  components: {
    VueEditor,
  },
  async created() {
    // console.log("Post: ", this.post);
    // console.log("Route: ", this.$route);
    // console.log("blogspagination: ", this.blogspagination);

    if (this.post == null) {
      if (this.$route.params.post != null) {
        this.activePost = Post.fromJson(this.$route.params.post);
      } else {
        // window.location.href = "/nieuws";
        // console.log("ERROR: ", this.post);
      }
    } else {
      this.activePost = Post.fromJson(this.post);
    }

    window.scrollTo(0, 0);
    if (this.post == null) {
      let id = this.$route.params.id;
      // console.log("ID: ", id);
      await PostService.getPost(id).then((response) => {
        this.activePost = response;
        // console.log("Post: ", this.post);
        this.activePost = Post.fromJson(response);
        // console.log("POST FETCH: ", this.activePost);
        if (this.post == null) {
        }
      });
    }
  },
  mounted() {},
  methods: {
    getAuthor() {
      let words = this.activePost.author.split(" ");
      let initials = "";
      words.forEach((word) => {
        if (word != "") initials = initials + word[0];
      });
      return initials;
    },
  },
};
</script>

<style lang="scss">
@import "@/custom_sass/components/_variables.scss";
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
.news-title {
  margin-bottom: 1.5rem;
  font-weight: bolder;
}

.author-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  .author-text-container {
    margin-left: 2rem;
  }
}

.news-img-container {
  width: 100%;
  margin-bottom: 2rem;
}

.ql-editor {
  padding: 0;
}

.ql-adapter > p {
  margin: 0 !important;
  margin-bottom: 0 !important;
}

.news-content-container {
  .v-application p {
    margin-bottom: 0 !important default;
  }

  p {
    margin-bottom: 0 !important;
  }
  @media #{$large-and-up} {
    width: 750px;
  }

  .news-img {
    width: 100%;
    @media #{$large-and-up} {
      height: 500px;
    }
  }
  margin: 0 auto !important;
}
</style>