import axios from 'axios';

import Post from '../data/Post';

// const BASE_URL = "http://me.kfchelson.com:8000/api/posts";
// const PUBLISHED_BASE_URL = "http://me.kfchelson.com:8000/api/published_posts";
const BASE_URL = "https://www.kfchelson.be/api/posts";
const PUBLISHED_BASE_URL = "https://www.kfchelson.be/api/published_posts";

// const BASE_URL = `${process.env.APP_URL}/api/posts`;
// const PUBLISHED_BASE_URL = `${process.env.APP_URL}/api/published_posts`;
const MAX_RETRIES = 3;
const RETRY_WAIT = 500;
export default class PostService
{
    constructor()
    {
        // this.token =
    }

    static async getAccesToken(attempt = 0)
    {
        let token = localStorage.getItem('kfch_access_token');
        if (token != null && token != "null" && token != "") return token;

        let check = (token == null || token == "null" || token == "") && attempt < MAX_RETRIES;
        while (check)
        {
            await PostService.__sleep(RETRY_WAIT)
                .then(async () =>
                {
                    token = localStorage.getItem('kfch_access_token');
                });
            check = (token == null || token == "null" || token == "") && attempt < MAX_RETRIES;
            if (!(token == null || token == "null" || token == ""))
                return token;
            attempt++;
        }
        throw Error("Problem getting access token!");
    }

    static __sleep(ms)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    static async getPosts(paginationParam = 10, page = '', publishedRequirement = false)
    {
        const ACCESSTOKEN = "Bearer " + await PostService.getAccesToken();
        if (page != '')
        {
            page = "?page=" + page
        }
        return await axios({
            method: 'GET',
            url: BASE_URL + page,
            params: { paginationParam: paginationParam, publishedRequirement: publishedRequirement },
            headers: { 'Content-Type': 'application/json', 'Authorization': ACCESSTOKEN }
        })
            .then(
                (response) =>
                {
                    let posts = response.data.data;
                    if (posts == null)
                        return [];

                    let parsedResponse = [];
                    posts.forEach(item =>
                    {
                        let post = Post.fromJson(item);
                        parsedResponse.push(post);
                        response.data.data = parsedResponse
                    });
                    return response;
                })
            .catch(
                (error) =>
                {
                    console.error("Eerror: ", error);
                    return error;
                });
    }

    static async getPublishedPosts(paginationParam = 10, page = '', publishedRequirement = false)
    {
        // const ACCESSTOKEN = "Bearer " + await PostService.getAccesToken();
        if (page != '')
        {
            page = "?page=" + page
        }
        return await axios({
            method: 'GET',
            url: PUBLISHED_BASE_URL + page,
            params: { paginationParam: paginationParam, publishedRequirement: publishedRequirement },
            headers: { 'Content-Type': 'application/json' }
        })
            .then(
                (response) =>
                {
                    let posts = response.data.data;
                    if (posts == null)
                        return [];

                    let parsedResponse = [];
                    posts.forEach(item =>
                    {
                        let post = Post.fromJson(item);
                        parsedResponse.push(post);
                        response.data.data = parsedResponse
                    });
                    return response;
                })
            .catch(
                (error) =>
                {
                    console.error("Eerror: ", error);
                    return error;
                });
    }

    static async getPost(id)
    {
        const ACCESSTOKEN = "Bearer " + await PostService.getAccesToken();
        return await axios({
            method: 'GET',
            url: BASE_URL + "/" + id,
            headers: { 'Content-Type': 'application/json', 'Authorization': ACCESSTOKEN }
        })
            .then(
                (response) =>
                {
                    let item = response.data;
                    let post = Post.fromJson(item);
                    return post;
                })
            .catch(
                (error) =>
                {
                    console.error("Eerror: ", error);
                    throw error;
                });


    }

    static async createPost(formData)
    {
        if (formData == "" || formData == null || formData == "null") throw Error("No formData provided!");
        const ACCESSTOKEN = "Bearer " + localStorage.getItem('kfch_access_token');
        // for (var pair of formData.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]);
        // }
        return await axios({
            method: 'POST',
            url: BASE_URL,
            data: formData,
            // headers: { 'Content-Type': 'application/json', 'Authorization': ACCESSTOKEN }
            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': ACCESSTOKEN }
        }).then(
            (response) =>
            {
                return response.data;
            })
            .catch(
                (error) =>
                {
                    console.error("Error: ", error);
                    throw error;
                });
    }

    static async updatePost(formData)
    {
        const ACCESSTOKEN = "Bearer " + await PostService.getAccesToken();
        return await axios({
            method: 'POST',
            url: BASE_URL,
            data: formData,
            // headers: { 'Content-Type': 'application/json', 'Authorization': ACCESSTOKEN }
            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': ACCESSTOKEN }
        })
            .then(
                (response) =>
                {
                    return response.data;
                })
            .catch(
                (error) =>
                {
                    console.error("Error: ", error);
                    throw error;
                });
    }

    static async deletePost(formData)
    {
        const ACCESSTOKEN = "Bearer " + await PostService.getAccesToken();
        return await axios({
            method: 'POST',
            url: BASE_URL,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': ACCESSTOKEN
            }
        })
            .then(
                (response) =>
                {
                    return response;
                })
            .catch(
                (error) =>
                {
                    console.error("Error: ", error);
                    throw error;
                });
    }


}
