<template>
  <div style>
    <h2 class="carousel-title">Onze partners</h2>
    <div id="frame">
      <div id="carousel" class="animate">
        <a href="#" class="carousel-img-href">
          <img class="img animate" src="/static/Logo/DMW.jpg" />
        </a>
        <a href="#" class="carousel-img-href">
          <img class="img animate" src="/static/Logo/Haletra.jpg" />
        </a>
        <a href="#" class="carousel-img-href">
          <img class="img animate" src="/static/Logo/Reynaers.jpg" />
        </a>
        <a href="#" class="carousel-img-href">
          <img class="img animate" src="/static/Logo/TerDolen.jpg" />
        </a>
        <a href="#" class="carousel-img-href">
          <img class="img animate" src="/static/Logo/Truyers.jpg" />
        </a>
        <a href="#" class="carousel-img-href">
          <img class="img animate" src="/static/Logo/TVH.jpg" />
        </a>
        <a href="#" class="carousel-img-href">
          <img class="img animate" src="/static/Logo/VAHEJA.jpg" />
        </a>
      </div>
    </div>
    <!-- <div id="controls">
        <button id="left" v-on:click="left()">&lt;</button>
        <span id="index">2</span>
        <button id="right" v-on:click="right()">&gt;</button>
    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      amount: 7,
      currTransl: [],
      translationComplete: true,
      carousel_item_width: 220,
      activeViewPort: 4,
      hovering: false,
    };
  },
  props: {
    viewport: {
      type: Number,
      default: () => {
        return 4;
      },
    },
    interval: {
      type: Number,
      default: () => {
        return 500;
      },
    },
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  // this.interval = 500000;
    // Prop initialisation safety

    // viewport
    // if (!this.viewport)
    // {
    //     this.viewport = 4;
    // }
    // else
    //     this.activeViewPort = this.viewport

    // // interval
    // if (!this.interval)
    // {
    //     this.interval = 500;
    // }
  },
  mounted() {
    this.amount = document.getElementsByClassName("img").length;
    // document.getElementById('index').innerHTML = this.amount;
    for (var i = 0; i < this.amount; i++) {
      this.currTransl[i] = -this.carousel_item_width;
      let el = document.getElementsByClassName("carousel-img-href")[i];
      el.addEventListener("transitionend", this.transitionCompleted, true);
      el.addEventListener(
        "webkitTransitionEnd",
        this.transitionCompleted,
        true
      );
      el.addEventListener("oTransitionEnd", this.transitionCompleted, true);
      el.addEventListener("MSTransitionEnd", this.transitionCompleted, true);
      el.addEventListener("mouseover", this.hoverEnterHandler);

      el.addEventListener("mouseenter", (e) => {
        this.hoverEnterHandler();
      });
      el.addEventListener("mouseout", (e) => {
        this.hoverLeaveHandler();
      });
      // el.addEventListener("mouseout", this.hoverLeaveHandler);
    }

    let carousel = document.getElementById("carousel");
    carousel.style.width = this.carousel_item_width * this.amount + "px";

    let frame = document.getElementById("frame");
    frame.style.width = this.carousel_item_width * this.activeViewPort + "px";
    this.resizeHandler();

    setInterval(() => {
      if (!this.hovering) this.left();
    }, this.interval);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  computed: {},
  methods: {
    hoverEnterHandler() {
      this.hovering = true;
    },
    hoverLeaveHandler() {
      this.hovering = false;
    },
    resizeHandler() {
      let resizeDirection =
        document.body.clientWidth <=
        this.carousel_item_width * this.activeViewPort
          ? -1
          : 1;
      if (resizeDirection === -1) {
        while (
          document.body.clientWidth <=
            this.carousel_item_width * this.activeViewPort &&
          this.activeViewPort - 1 > 0
        ) {
          this.activeViewPort += resizeDirection;
        }
      } else if (resizeDirection === 1) {
        while (
          document.body.clientWidth >=
            this.carousel_item_width * (this.activeViewPort + 1) &&
          this.activeViewPort < this.viewport
        ) {
          this.activeViewPort += resizeDirection;
        }
      }

      let frame = document.getElementById("frame");
      frame.style.width = this.carousel_item_width * this.activeViewPort + "px";
    },
    transitionCompleted() {
      this.translationComplete = true;
    },
    mod(m, n) {
      return ((m % n) + n) % n;
    },
    right() {
      if (this.translationComplete === true) {
        this.translationComplete = false;
        this.index--;
        if (this.index == -1) {
          this.index = this.amount - 1;
        }
        var outerIndex = this.index % this.amount;
        if (document.getElementById("index"))
          document.getElementById("index").innerHTML =
            outerIndex === 0 ? 5 : outerIndex;
        for (var i = 0; i < this.amount; i++) {
          var img = document.getElementsByClassName("img")[i];
          if (!img) continue;
          img.style.opacity = "1";
          img.style.transform =
            "translate(" +
            (this.currTransl[i] + this.carousel_item_width) +
            "px)";
          //img.className = 'img';
          //img.className.replace( /(?:^|\s)animate(?!\S)/g , '' );
          this.currTransl[i] = this.currTransl[i] + this.carousel_item_width;
        }

        var outerImg = document.getElementsByClassName("img")[outerIndex];
        if (outerImg) {
          outerImg.style.transform =
            "translate(" +
            (this.currTransl[outerIndex] -
              this.carousel_item_width * this.amount) +
            "px)";
          outerImg.style.opacity = "0";
          this.currTransl[outerIndex] =
            this.currTransl[outerIndex] -
            this.carousel_item_width * this.amount;
        }
      }
    },
    left() {
      if (this.translationComplete === true) {
        this.translationComplete = false;
        this.index++;
        var outerIndex = (this.index - 1) % this.amount;
        if (document.getElementById("index"))
          document.getElementById("index").innerHTML = outerIndex + 1;
        for (var i = 0; i < this.amount; i++) {
          var img = document.getElementsByClassName("img")[i];
          if (!img) continue;
          img.style.opacity = "1";
          img.style.transform =
            "translate(" +
            (this.currTransl[i] - this.carousel_item_width) +
            "px)";
          this.currTransl[i] = this.currTransl[i] - this.carousel_item_width;
        }
        var outerImg = document.getElementsByClassName("img")[outerIndex];
        if (outerImg) {
          outerImg.style.transform =
            "translate(" +
            (this.currTransl[outerIndex] +
              this.carousel_item_width * this.amount) +
            "px)";
          outerImg.style.opacity = "0";
          this.currTransl[outerIndex] =
            this.currTransl[outerIndex] +
            this.carousel_item_width * this.amount;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/custom_sass/components/_variables.scss";
$_carousel-height: 220px;
$_carousel-width: 880px;
$_carousel-item-width: 200px;
$_carousel-item-horizontal-margin: 10px;

.carousel-title {
  text-align: center;
  font-family: "Amaranth", sans-serif;
  margin-bottom: 4rem;

  @media #{$small-and-down} {
      margin-bottom: 0;
    }

    
}

#frame {
  width: $_carousel-width;
  height: $_carousel-height;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

#carousel {
  position: relative;
  // width: 1000px;
  height: $_carousel-height;
  display: table;

  a {
    display: table-cell;
    vertical-align: middle;

    height: 220px;
    // @media #{$small-and-down} {
    //   height: 150px;
    // }
  }
}

.animate {
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
}

img {
  position: relative;
  float: left;
  // transform: translate(-($_carousel-item-horizontal-margin + $_carousel-item-width));
  transform: translate(-220px);
  // left: 200px;
  max-width: $_carousel-item-width;
  max-height: 100%;
  display: block;
  margin: 0 $_carousel-item-horizontal-margin;
}

#controls {
  position: relative;
  // left: 300px;
  width: 200px;
}

#left {
  float: left;
  width: 33.3%;
}

#index {
  display: inline-block;
  float: left;
  width: 33.3%;
  text-align: center;
}

#right {
  float: right;
  width: 33.3%;
}
</style>
