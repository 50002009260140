<template>
  <div>
    <router-view :blogspagination="blogspagination" :post="modPost"></router-view>
  </div>
</template>

<script>
import Post from '../data/Post';
export default {
  inject: ["theme"],
  props: ["blogspagination", "post"],
  data() {
    return {
      modPost: null
    };
  },
  created() {
    if(this.post) this.modPost = Post.fromJson(this.post);
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/custom_sass/components/_variables.scss";
</style>