<template>
  <div>
    <!-- <v-card> -->
    <v-toolbar flat color="transparent">
      <v-toolbar-title>Club</v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="selectedTab" vertical hide-slider>
      <v-tab class="d-none d-lg-block" v-for="(tab, index) in clubinfo_tabs_headers" :key="index">
        <v-icon left>{{tab.icon}}</v-icon>
        {{tab.text}}
      </v-tab>

      <!-- <v-tab-item v-for="(tab, index) in clubinfo_tabs" :key="index">
        <v-card flat>
          <v-card-text v-html="tab"></v-card-text>
        </v-card>
      </v-tab-item>-->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <div class="row">
              <div class="col-12 col-sm-9">
                <p>Naam: KFC Helson Helchteren</p>
                <p>Stamnummer: 06045</p>
                <p>Bloknummer: 2050</p>
                <p>Kleuren: rood – zwart - wit</p>
                <p>
                  Mail:
                  <a href="mailto:fchelson@telenet.be">fchelson@telenet.be</a>
                </p>
                <p class="multiline-info">
                  Adres:
                  <span style="margin-left: 1rem;">
                    <a
                      href="https://www.google.be/maps/place/KFC+Helson/@51.0620591,5.385907,17z/data=!3m1!4b1!4m5!3m4!1s0x47c12898cce043b7:0x397fb2a99c2784ab!8m2!3d51.0620591!4d5.3880957"
                    >
                      <span class="multiline-info-line">Sportstraat z/n,</span>
                      <span class="multiline-info-line">3530 Houthalen-Helchteren</span>
                    </a>
                  </span>
                </p>
                <p>
                  Telefoon:
                  <a href="tel:>011521300">011/52.13.00</a>
                </p>
                <p class="multiline-info">
                  Verhuringen:
                  <span style="margin-left: 1rem;">
                    <span class="multiline-info-line">………………….. – GSM …………………</span>
                    <span class="multiline-info-line">(niet mogelijk zolang er Covid-19 maatregelen gelden)</span>
                  </span>
                </p>
                <p class="multiline-info">
                  Bankrekening:
                  <span style="margin-left: 1rem;">
                    <span class="multiline-info-line">FC Helson Helchteren</span>
                    <span class="multiline-info-line">BE87 7350 1192 2294</span>
                  </span>
                </p>
              </div>
              <div class="col-12 col-sm-3">
                <v-img class="logo-display" src="/img/kfc.png" lazy-src="/img/kfc.png"></v-img>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.</p>

            <p
              class="mb-0"
            >Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nam at tortor in tellus interdum sagittis.</p>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <!-- </v-card> -->
  </div>
</template>

<script>
import Post from "../data/Post";
import PostService from "../services/PostService";
export default {
  inject: ["theme"],
  props: {
    selected:
        {
            type: String,
            default: () => {return "";}
        },
  },
  data() {
    return {
      selectedTab: null,
      clubinfo_tabs_headers: [
        // { icon: "mdi-account", text: "Algemeen" },
        { icon: "", text: "Algemeen", href: "/clubinfo" },
        { icon: "", text: "Algemeen", href: "/clubinfo" },
      ],
      clubinfo_tabs: [
        "<p>Sed aliquam ultrices mauris. Donec posuere vulputate arcu. Morbi ac felis. Etiam feugiat lorem non metus. Sed a libero.</p><p>Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc. Aliquam lobortis. Aliquam lobortis. Suspendisse non nisl sit amet velit hendrerit rutrum.</p><p class='mb-0'>Phasellus dolor. Fusce neque. Fusce fermentum odio nec arcu. Pellentesque libero tortor, tincidunt et, tincidunt eget, semper nec, quam. Phasellus blandit leo ut odio.</p>",
      ],
    };
  },
  async created() {},
  mounted() {
    this.selectedTab = this.selected;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/custom_sass/components/_variables.scss";
.multiline-info {
  display: flex;
  flex-direction: row;
}

.multiline-info-line {
  display: inline-block;
}
.logo-display {
  margin: 0 auto;
  // @media #{$medium-and-down} {
  //   width: 50%;
  // }

  @media #{$small-and-down} {
    width: 70%;
  }
}
</style>