<template>
  <div>
    <v-carousel height="600" hide-delimiter-background show-arrows-on-hover>
      <v-carousel-item :key="0" src="img/tribuun.jpg">
        <div class="carousel-item-overlay">
          <v-row class="fill-height" align="center" justify="center" style="margin:0;">
            <div class="display-3 carousel-item-title">
              Welkom bij KFC Helson
              <br />
              <span
                style="font-size: 1.5rem;
    text-decoration: underline; text-decoration-color: #e51c23 !important;"
              >(Website nog in ontwikkeling)</span>
            </div>
          </v-row>
        </div>
      </v-carousel-item>
      <!-- <v-carousel-item v-for="(slide, i) in slides" :key="i+1">
            <v-sheet :color="colors[i]" height="100%">
                <v-row class="fill-height" align="center" justify="center">
                    <div class="display-3">{{ slide }} Slide</div>
                </v-row>
            </v-sheet>
      </v-carousel-item>-->
    </v-carousel>

    <!-- <v-skeleton-loader
    style="height: 600px"
        ref="skeleton"
        type="image"
        tile="tile"
        class="mx-auto"
        loading
    ></v-skeleton-loader>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
  mounted() {
    // console.log("Emitting");
    let data = "Loaded homeslider";
    window.eventBus.$emit("dataLoaded", data);
  },
};
</script>

<style lang="scss" scoped>
@import "@/custom_sass/components/_variables.scss";
.carousel-item-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);
}

.carousel-item-title {
  font-weight: bold;
  font-family: "Amaranth", sans-serif !important;
  text-align: center;
  @media #{$medium-and-down} {
    font-size: 2.5rem !important;
    text-align: center;
  }
}
</style>